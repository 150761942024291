.container {
  width: 100%;
  max-width: var(--container-max-width);
  padding: 0 var(--gap);
  margin: 0 auto;

  .container {
    padding: 0;
  }
}

.main,
.footer {
  @media (--media-md) {
    padding-right: var(--menu-width);
  }
}

.logo {
  color: inherit;
  display: block;
}

.logo__img {
  display: block;
  width: max(15.25rem, 50%);
}

.intro-logo {
  margin-top: var(--gap-l);
  margin-bottom: calc(2 * var(--gap-l));

  @media (--media-sm-only) {
    display: none;
  }
}

.services-nav {
  font-size: var(--fs-h3);
  font-style: italic;
  line-height: 1.3;

  a {
    font-size: var(--fs-h3);
  }
}

.services-nav.-vertical {
  margin-top: var(--gap-s);
  margin-bottom: var(--gap-l);
  padding-right: var(--gap);
  > * {
    margin-bottom: 0.125rem;
  }
}

.services-nav.-horizontal {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 6px;
  margin-bottom: var(--gap-l);

  @media (--media-sm-only) {
    padding-top: var(--margin);
  }
}

.services-nav__service {
  /* background-color: var(--color-services); */
  line-height: 1.25;
  position: relative;

  span {
    background-color: var(--color-services);

    &:hover,
    &:focus-visible,
    .active {
      background-color: var(--color-services--strong);
      color: var(--black);
    }
  }

  .-horizontal & {
    &:not(:last-child)::after {
      content: ",";
      background-color: var(--bg);
    }
  }
}

.intro {
  margin-bottom: var(--margin);
}

.intro__text {
  margin-bottom: var(--margin-l);
  line-height: 1.25;
}

/* XXX: to discuss: should we do classes for whitespaces and sizes instead?
But please only for sizes, and 1 container class and 1 grid class, that's the most tailwindify I would bear/accept :)  */
.section,
.customers,
.consultant,
.default-subregion,
.projects,
.project,
.articles,
.flips-wrapper,
.services,
.gallery,
[class^="directive"],
[class*=" directive"] {
  margin-bottom: var(--margin);

  .home-contents & {
    margin-bottom: var(--margin-l);
  }
}

.project-detail-container {
  [class^="directive"],
  [class*=" directive"] {
    &:not(:last-child) {
      gap: var(--gap);
      margin-bottom: var(--margin);
    }
  }
}

.project .default-subregion {
  margin-bottom: 0;
}

.standart-page,
.services {
  margin-top: var(--margin-l);
}

.gallery {
  margin-top: var(--margin-s);
}

/*TODO: .leadtext does not exist yet */
.leadtext,
.service > *:first-child {
  line-height: 1.25;

  h1 {
    font-size: var(--fs-section);
  }
  h2 {
    font-size: var(--fs-call);
  }
  h3 {
    font-size: var(--fs-h2);
  }
  h4 {
    font-size: var(--fs-h3);
  }
  h5 {
    font-size: var(--fs-h4);
  }
  p,
  ul,
  ol {
    font-size: var(--fs-h3);
  }
}

.leadtext {
  margin-bottom: var(--margin-s);
}
