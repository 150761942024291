.section-title {
  font-size: var(--fs-section);
  line-height: 1.15;
  margin-bottom: var(--margin-s);

  :is(a) {
    color: inherit;
    text-decoration: none;
  }
}

:where(h1, h2, h3, h4, h5, h6) {
  hyphens: auto;
  margin-top: 0;
}

:where(h1) {
  font-size: var(--fs-call);
  line-height: 1.2;
  margin-bottom: 0.25em;
}

:where(h2) {
  font-size: var(--fs-h2);
  line-height: 1.25;
  margin-bottom: 0.25em;
}

:where(h3) {
  font-size: var(--fs-h3);
  line-height: 1.3;
  margin-bottom: 0.25em;
}

:where(h4) {
  font-size: var(--fs-h4);
  line-height: 1.4;
  margin-bottom: 0;
}

:where(h5) {
  font-size: var(--fs-small);
  line-height: 1.4;
  margin-bottom: 0;
}

:where(.lead-title) {
  font-size: var(--fs-section);
  line-height: 1.15;
}

.article__content :where(h2, h3, h4) {
  font-size: var(--fs-h4);
  line-height: 1.4;
  margin-bottom: 0;

  + :where(p, ul, ol) {
    margin-top: 0;
  }
}
